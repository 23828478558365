// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

(function($) {
       $.quicknotifyplugin = {
              defaultsquicknotifyToolParam: {  
                     quicknotifyContainer: "_quicknotifycontainer",
                     HotelId:'26',
                     langkey:"en",
                     notecolor:"#FFECAF",
                     txtcolor:"#5A5134",
                     serverurl:"https://live.ipms247.com/",
              }
       };
	
	$.fn.extend({
        quicknotifyplugin:function(bkQNConfig) {
            var options = $.extend({}, $.quicknotifyplugin.defaultsquicknotifyToolParam, bkQNConfig);
            options.quicknotifyContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _GetDataContain:function(opt){
                try{
                    var getUrl=opt.serverurl+'booking/service.php';
                    var param='action=GetQuickNotify&lang_code='+opt.langkey+'&HotelId='+opt.HotelId;
                    jQuery.ajax({
                        type: "POST",
                        url: getUrl,
                        data: param,
                        success: function(response) {
                            var res = JSON.parse(jQuery.trim(response));
                            var QuickNotify = res.content.split('<:>');
                            var QuickNotifyFlag = res.flag.split('<:>');
                            var flag = 0;
                            
                            if(QuickNotifyFlag[0] == 1) //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - Quick Notification flag : LookerTOBookerStayWidget
                            {
                                jQuery.get(opt.serverurl+'booking/service.php?HotelId='+opt.HotelId+'&action=recentbooking', function(recentbookingdata) {
                                    if(recentbookingdata!='')
                                    {
                                        flag = flag + 1;
                                        methods.FindRecentbooking(opt,recentbookingdata,QuickNotify[0],flag);
                                    }
                                });
                            }
                            if(QuickNotifyFlag[1] == 1) //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - Quick Notification flag : LookerTOBookerStayWidget
                            {
                                jQuery.get(opt.serverurl+'booking/service.php?HotelId='+opt.HotelId+'&action=reviewalert', function(reviewalertdata) {
                                    var res=reviewalertdata.split("|");
                                    if(res[1]!='')
                                    {
                                        flag = flag + 1;
                                        methods.WebReviewAlert(opt,res[0],res[1],res[2],QuickNotify[1],flag);
                                    }
                                });
                            }
                            if(QuickNotifyFlag[2] == 1) //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - Quick Notification flag : LookerTOBookerStayWidget
                            {
                                jQuery.get(opt.serverurl+'booking/service.php?HotelId='+opt.HotelId+'&action=countryguestcount', function(countryguestcountdata) {
                                    if(countryguestcountdata!='')
                                    {
                                        var res=countryguestcountdata.split("-");
                                        flag = flag + 1;
                                        methods.TotalGuestCountryWise(opt,res[0],res[1],QuickNotify[2],flag);
                                    }
                                });
                            }
                            if(QuickNotifyFlag[3] == 1) //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - Quick Notification flag : LookerTOBookerStayWidget
                            {
                                jQuery.get(opt.serverurl+'booking/service.php?HotelId='+opt.HotelId+'&action=StayPeopleLastWeek', function(staypeople) {
                                    if(staypeople!=0)
                                    {
                                        flag = flag + 1;
                                        methods.StayPeopleLastWeek(opt,staypeople,QuickNotify[3],flag);
                                    }
                                });
                            }
                            window.stack_bottomleft = {"dir1": "up", "dir2": "right", "spacing1": 5, "spacing2": 5};
                        },
                        complete: function(){
                             
                        }
                    });
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            WebReviewAlert:function(opt,tot_reviews,tot_scores,showreview,note,flag){
                try{
                    if(tot_reviews > 0 && showreview==1 )
                    {
                        var timming = methods._SetNoticeTimming(flag);
                        var review_scores=parseFloat(tot_scores).toFixed(1);   
                        var StrReviewNotice = note.replace('{rates}', '<b>'+review_scores+' / 5</b>');
                
                        setTimeout(function(){
                            var noticereview = new PNotify({
                                text: StrReviewNotice,
                                delay : 5000,
                                addclass : 'stack-bottomleft',
                                stack : stack_bottomleft,
                                icon : 'fa fa-star-half-empty',
                                buttons: {
                                    closer: true,
                                    sticker: false,
                                    closer_hover : false
                                },
                                animate: {
                                    animate: true,
                                    in_class: 'bounceInLeft',
                                    out_class: 'bounceOutLeft'
                                },
                                 mobile: {
                                        swipe_dismiss: true,
                                        styling: false
                                },
                            });
                            jQuery('.brighttheme-notice').css({'background-color':opt.notecolor});
                            jQuery('.brighttheme-notice').css({'color':opt.txtcolor});
                        }, timming);
                    }
                }
                catch(err){
                      console.log('error message WebReviewAlert');
                      console.log(err);
                }
            },
            FindRecentbooking:function(opt,TotRecentBooking,note,flag){
                try{                    
                    if(TotRecentBooking > 0 )
                    {
                        var timming = methods._SetNoticeTimming(flag);
                        var recentbooking_msg = note.replace('{number}', jQuery.trim(TotRecentBooking));
                        setTimeout(function(){
                            var notice = new PNotify({
                                text: recentbooking_msg,
                                delay : 5000,
                                addclass : 'stack-bottomleft',
                                stack : stack_bottomleft,
                                icon : 'fa fa-calendar-check-o',
                                buttons: {
                                    closer: true,
                                    sticker: false,
                                    closer_hover : false
                                },
                                animate: {
                                    animate: true,
                                    in_class: 'bounceInLeft',
                                    out_class: 'bounceOutLeft'
                                },
                                mobile: {
                                        swipe_dismiss: true,
                                        styling: false
                                },
                            });
                            jQuery('.brighttheme-notice').css({'background-color':opt.notecolor});
                            jQuery('.brighttheme-notice').css({'color':opt.txtcolor});
                        }, timming);
                    }
                }
                catch(err){
                    console.log('error message FindRecentbooking');
                    console.log(err);
                }
            },
            TotalGuestCountryWise:function(opt,guest_country,noof_guest,note,flag){
                try{
                    if(noof_guest > 0)
                    {
                        var timming = methods._SetNoticeTimming(flag);
                        var guest_msg = note.replace('{country}', jQuery.trim(guest_country));
                        var Guest_notify = guest_msg.replace('{totalguest}', noof_guest);
                        
                        setTimeout(function(){
                            var noticecountry = new PNotify({
                                text: Guest_notify,
                                delay : 5000,
                                addclass : 'stack-bottomleft',
                                stack : stack_bottomleft,
                                icon : 'fa fa-globe',
                                buttons: {
                                    closer: true,
                                    sticker: false,
                                    closer_hover : false
                                },
                                animate: {
                                    animate: true,
                                    in_class: 'bounceInLeft',
                                    out_class: 'bounceOutLeft'
                                },
                                 mobile: {
                                        swipe_dismiss: true,
                                        styling: false
                                },
                            });
                            jQuery('.brighttheme-notice').css({'background-color':opt.notecolor});
                            jQuery('.brighttheme-notice').css({'color':opt.txtcolor});
                        }, timming);
                    }
                }
                catch(err){
                    console.log('error message TotalGuestCountryWise');
                    console.log(err);
                }
            },
            //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - Start
            //Purpose : Quick Notification flag : LookerTOBookerStayWidget
            StayPeopleLastWeek:function(opt,staypeople,note,flag){
                try{
                    if(staypeople > 0 )
                    {
                        var timming = methods._SetNoticeTimming(flag);
                        var staynotice = note.replace('{StayPeople}', jQuery.trim(staypeople));
            
                        setTimeout(function(){
                            var notice = new PNotify({
                                text: staynotice,
                                delay : 5000,
                                addclass : 'stack-bottomleft',
                                stack : stack_bottomleft,
                                icon : 'fa fa-calendar-check-o',
                                buttons: {
                                    closer: true,
                                    sticker: false,
                                    closer_hover : false
                                },
                                animate: {
                                    animate: true,
                                    in_class: 'bounceInLeft',
                                    out_class: 'bounceOutLeft'
                                },
                                mobile: {
                                        swipe_dismiss: true,
                                        styling: false
                                },
                            });
                            jQuery('.brighttheme-notice').css({'background-color':opt.notecolor});
                            jQuery('.brighttheme-notice').css({'color':opt.txtcolor});
                        }, timming);
                    }
                }
                catch(err){
                    console.log('error message StayPeopleLastWeek');
                    console.log(err);
                }
            },
            _SetNoticeTimming:function(flag){
                try{
                    
                    if(flag == 1)
                        var timming = 3000;
                    else if(flag == 2)
                        var timming = 10000;
                    else if(flag == 3)
                        var timming = 17000;
                    else if(flag == 4)
                        var timming = 25000;
                        
                    return timming;
                }
                catch(err){
                    console.log('error message FindRecentbooking');
                    console.log(err);
                }
            }
            //Chinmay Gandhi - 1.0.53.61 - 23rd Jan 2018 - End
    }
       	
})(jQuery); 
